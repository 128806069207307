import * as React from "react";
import { Container, Heading, Stack, Box, Text, Button } from "@chakra-ui/react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Seo from "../../components/SEO";
import Main from "../../components/layouts/Main";
import SmallNav from "../../components/smallNav";
import SubmitClaimComp from "../../components/whatToExpect/SubmitClaim";
import ExamComplaintsComp from "../../components/submitClaim/ExamComplaintsComp";
import usePrismicSubmitClaim from "../../hooks/usePrismicSubmitClaim";
// TODO: above there is already react import, duplicated
// TODO: make sure to move all node_modules imports above
import { useEffect } from "react";
import { Navigation } from "swiper";
import LeftButton from "../../assets/arrow.svg";
import RightButton from "../../assets/right.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import usePrismicRepeatSubmitClaim from "../../hooks/usePrismicRepeatSubmitClaim";
import RichText from "../../components/PrismicRichText";
import YoutubeFrames from "../../components/Youtube";
import UsefulLinks from "../../components/usefulLinks";
const isBrowser = () => typeof window !== "undefined";
const SubmitClaim = () => {
  const { language } = useTranslation();
  const section = isBrowser() && window.location.hash.replace("#", "");

  const scrollToRef = (offsetTop) => window.scrollTo(0, offsetTop);
  const offSetTop = (ref) => ref.current.offsetTop - 150;
  const scrollRef = React.useRef(null);
  const submitRef = React.useRef(null);
  const usefulRef = React.useRef(null);
  const videoRef = React.useRef(null);
  const data = usePrismicSubmitClaim();
  const { informativeVideo } = usePrismicSubmitClaim();
  const submitAClaim = usePrismicRepeatSubmitClaim();

  useEffect(() => {
    if (section === "find-out") {
      scrollToRef(offSetTop(scrollRef));
    }
    if (section === "claim") {
      scrollToRef(offSetTop(submitRef));
    }
    if (section === "useful-links") {
      scrollToRef(offSetTop(usefulRef));
    }
    if (section === "informative-video") {
      scrollToRef(offSetTop(videoRef));
    }
  }, [section]);

  return (
    <Main>
      <Container px="5" maxW="7xl">
        <Seo
          title={data.headerTitle}
          description={data.headerTitle}
          link={`https://www.fsm.am/${language}/submit-claim`}
        />
        <Box px="5">
          <SmallNav>{data.headerTitle}</SmallNav>
          <Heading
            as="h1"
            fontSize={{ base: "2xl", sm: "3xl" }}
            fontWeight="900"
            mt={{ base: "3", sm: "5" }}
            mb="3.75rem"
          >
            {data.headerTitle}
          </Heading>
        </Box>
        <Stack direction={{ base: "column", md: "row" }} spacing="10rem">
          <Box>
            <Text px="5" fontSize="xl" mb="6">
              {data.headerSubTitle}
            </Text>
            <Box px="5" fontSize="sm" color="grey.100" mb="3.625rem">
              <RichText text={data.description} />
            </Box>
            <ExamComplaintsComp
              scrollPosition={submitRef}
              scrollTo={scrollToRef}
              ref={scrollRef}
            />
          </Box>
          {data?.usefulLinks && (
            <UsefulLinks ref={usefulRef} usefulLinks={data.usefulLinks} />
          )}
        </Stack>
      </Container>

      <Box
        ref={submitRef}
        w="full"
        bg="#FBFBFB"
        py={{ base: "2rem", sm: "5rem" }}
        mb="3.75rem"
      >
        <SubmitClaimComp submitClaim={submitAClaim} />
      </Box>
      {data.informativeVideo.video.length > 1 && (
          <Container px="5" ref={videoRef} maxW="7xl" mb="8.125rem">
            <Text fontSize="xl" mb="1.875rem">
              {informativeVideo.title}
            </Text>

            <Swiper
              modules={[Navigation]}
              navigation={{
                nextEl: ".right",
                prevEl: ".left",
              }}
              spaceBetween={28}
              slidesPerView={3}
              scrollbar={{ draggable: true }}
              style={{ width: "100%", height: "100%" }}
            >
              {data.informativeVideo.video.map((slide, idx) => (
                <SwiperSlide
                  key={`slide-${slide.title}-${idx}`}
                  style={{ width: "100%", height: "100%" }}
                >
                  <YoutubeFrames
                    idx={idx}
                    src={slide.link}
                    title={slide.title}
                    thumbnail={slide.thumbnail}
                  />
                </SwiperSlide>
              ))}
              <Stack spacing="3rem" mt="2rem" direction="row">
                <Box className="left" as={Button}>
                  <LeftButton />
                </Box>
                <Box className="right" as={Button}>
                  <RightButton />
                </Box>
              </Stack>
            </Swiper>
  </Container>
)}

    </Main>
  );
};

export default SubmitClaim;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
