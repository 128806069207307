import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next'

const usePrismicSubmitClaim = () => {
    const { language } = useI18next()
    const newLang = language === 'en' ? 'en-us' : language === 'ru' ? 'ru' : 'hy-am'

    const response = useStaticQuery(graphql`
    query PrismicSubmitClaim {
      allPrismicSubmitclaim(filter: {tags: {in: "submit_a_claim"}}) {
        nodes {
          lang
          data {
            title {
              text
            }
            description1 {
              richText
            }
            linkgroup {
              linktitle
              link {
                url
              }
            }
            headertitle {
              text
            }
            headersubtitle {
              text
            }
            description {
              richText
            }
            title1 {
              text
            }
            informativevideo {
              title {
                text
              }
              link {
                url
              }
              thumbnail {
                url
              }
            }
          }
        }
      }
    }
  `);
    return sanitize(response.allPrismicSubmitclaim.nodes.filter(item => item.lang === newLang)[0].data);
};

const sanitize = (response) => {
    return {
        headerTitle: response.headertitle.text,
        description: response.description.richText,
        headerSubTitle: response.headersubtitle.text,
        informativeVideo: {
            title: response.title1.text,
            video: response.informativevideo.map(video => ({
                link: video.link.url,
                title: video.title.text,
                thumbnail: video.thumbnail.url,
            })),
        },
        usefulLinks: {
            title: response.title.text,
            description: response.description1.richText,
            links: response.linkgroup.map(link => ({
                title: link.linktitle,
                href: link.link.url
            }))
        }
    }
}

export default usePrismicSubmitClaim;