import React, {useState} from "react";
import {Box, Flex, Text} from "@chakra-ui/react";
import Play from "../../assets/play.svg";

const YoutubeFrames = (
    {
        idx,
        src,
        title,
        thumbnail
    }
) => {
    const [play, setPlay] = useState(false);
    const [videoIdx, setVideoIdx] = useState(null);
    const url = play ? '?autoplay=1&controls=1&enablejsapi=1&rel=0' : '?controls=0'

    const setPlaySettings = (idx) => {
        setPlay(true)
        setVideoIdx(idx)
    };
    return (
        <>
            {
                play && idx === videoIdx ? (
                    <Box>
                        <Box
                            as="iframe"
                            maxW="372px"
                            w="full"
                            h="230px"
                            objectFit="cover"
                            src={`${src}${url}`}
                            allow='autoplay; encrypted-media'
                            borderRadius="16px"
                        />
                        <Text mt="1rem" color="grey.100">
                            {title}
                        </Text>
                    </Box>

                ) : (
                    <Box>
                        <Flex
                            h={{base: '230px', sm: "230px"}}
                            bgImage={`url(${thumbnail})`}
                            bgSize="cover"
                            bgPos="center"
                            bgRepeat="no-repeat"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="16px"
                            width="100%"
                        >
                            <Box
                                cursor="pointer"
                                onClick={() => setPlaySettings(idx)}
                                p="5"
                                backgroundColor="rgba(0, 0, 0, .4)"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                borderRadius="100px"
                                width="80px"
                                height="80px"
                            >
                                <Play width="27px"/>
                            </Box>
                        </Flex>
                        <Text mt="1rem" color="grey.100">
                            {title}
                        </Text>
                    </Box>
                )
            }
        </>
    )
}

export default YoutubeFrames;