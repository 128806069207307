import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next'

const usePrismicRepeatSubmitClaim = () => {
    const { language } = useI18next()
    const newLang = language === 'en' ? 'en-us' : language === 'ru' ? 'ru' : 'hy-am'

    const response = useStaticQuery(graphql`
    query PrismicRepeatSubmitClaim {
      allPrismicSubmitaclaim(filter: {tags: {in: "submit_claim_repeat"}}) {
        nodes {
          lang
          data {
            title {
              text
            }
            buttontext
            image {
              url
            }
          }
        }
      }
    }
  `);
    return sanitize(response.allPrismicSubmitaclaim.nodes.filter(item => item.lang === newLang)[0].data);
};

const sanitize = (response) => {
    return {
        title: response.title.text,
        buttonText: response.buttontext,
        image: response.image.url,
    }
}

export default usePrismicRepeatSubmitClaim;