import React from 'react'
import {Box, Container, Flex, Heading, Button, Image, Link } from '@chakra-ui/react'
import { CREATE_DEMAND_APPLICATION_URL } from '../../static/constants'

const SubmitClaim = React.forwardRef(({ submitClaim },ref) => {
  return(
    <Container ref={ref} maxW="7xl" h="200px" my="5rem">
      <Flex
        boxShadow="0 22px 34px 0 rgba(0,0,0,0.15)"
        bg="#82796d"
        h="100%"
        justifyContent="space-between"
        borderRadius="24px"
        overflow="hidden"
        >
        <Flex
          flex="1"
          direction="column"
          alignItems={{base: 'center', sm: "flex-start"}}
          justifyContent="center"
          px={{base: '0', sm: "5.875rem"}}
          position="relative">
          <Box
            position="absolute"
            left="100%"
            height="100%"
            zIndex="9"
            width="200px"
            background="linear-gradient(76.07deg, #82796d 17%, rgba(108,104,97,0) 100%)"/>
            <Heading
              as="h3"
              fontSize="2xl"
              color="white"
              >
              {submitClaim.title}
            </Heading>
            <Button 
              as={Link}
              href={CREATE_DEMAND_APPLICATION_URL}
              variant="darkGreen" 
              w="220px" 
              mt="6" 
              target="_blank">
              {submitClaim.buttonText}
            </Button>
        </Flex>
        <Image
          display={{base: 'none', sm: 'block'}}
          w="647px"
          objectFit="cover"
          src={submitClaim.image}
          />
      </Flex>
    </Container>
  )
})

export default SubmitClaim