import React from "react";
import { Box, Stack, Heading, Button } from "@chakra-ui/react";
import RichText from "../PrismicRichText";
import { getLinkProps } from "../../helpers";
const UsefulLinks = React.forwardRef(({ usefulLinks }, ref) => {
  return (
    <Box
      ref={ref}
      maxW="300px"
      height="100%"
      py={{ base: "0", sm: "6" }}
      pl={{ base: "0", md: "6" }}
      borderLeft={{ base: "none", md: "1px solid #dddddd" }}
      sx={{ position: "sticky", top: "140" }}
    >
      <Heading as="h2" fontSize="md" mb="1rem">
        {usefulLinks?.title}
      </Heading>
      {usefulLinks?.description && (
        <Box fontSize="sm" color="grey.100">
          <RichText text={usefulLinks.description} />
        </Box>
      )}
      <Stack alignItems="flex-start" spacint="8">
        {usefulLinks?.links?.map((link, idx) => {
          console.log(link.href)
          return (
            <Button
              key={`links-${usefulLinks.id}-${idx}`}
              title={link.title}
              target="_blank"
              mt="1rem"
              variant="text"
              color="darkgreen"
              display="block"
              overflow="hidden"
              maxW="100%"
              textOverflow="ellipsis"
              lineHeight="23px"
              {...getLinkProps(link.href)}
            >
              {link.title}{" "}
            </Button>
          );
        })}
      </Stack>
    </Box>
  );
});

export default UsefulLinks;
