import React from "react";
import {
  Box,
  Heading,
  Flex,
  Button,
  Text,
  Stack,
  SimpleGrid,
} from "@chakra-ui/react";
import {
  useState,
  useEffect,
} from "react";
import { Trans } from "react-i18next";
import { useI18next } from "gatsby-plugin-react-i18next";
import data from "./questionTree.json";

const ExamComplaintsComp =
  React.forwardRef((props, ref) => {
    const { language } = useI18next();
    const [answers, setAnswers] =
      useState({
        0: "",
        1: "",
        2: "",
        3: "",
      });
    const [question, setQuestion] =
      useState(0);
    const questionTree =
      data[language].tree;
    useEffect(() => {
      if (!props.scrollPosition.current)
        return;
      // WE MIGHT NEED IT LATER
      // if (
      //   !Object.values(answers).includes("") &&
      //   !Object.values(answers).includes("No")
      // ) {
      //   props.scrollTo(props.scrollPosition)
      // }
    }, [answers, question]);
    return (
      <Box
        px="5"
        ref={ref}
        w="full"
        bg="green.200"
        py={{ base: "10", sm: "5rem" }}
        borderRadius={{
          base: "0",
          sm: "16px",
        }}
        mt={20}
        mb="3.75rem"
      >
        <Heading
          as="h2"
          color="white"
          fontSize="2xl"
          textAlign="center"
        >
          {data[language].title}
        </Heading>
        <Flex
          justifyContent="center"
          mt="3rem"
        >
          <Box
            maxW="48.25rem"
            w="100%"
            py="10"
            px="5"
            bg="white"
            boxShadow="0 12px 34px 0 rgba(0,0,0,0.15)"
            borderRadius="20px"
          >
            <Text
              fontSize="lg"
              textAlign="center"
              dangerouslySetInnerHTML={{
                __html:
                  questionTree[question]
                    .question,
              }}
            />
            {answers[question] ? (
              <Text
                mt="10"
                color={
                  Object.keys(
                    questionTree[
                      question
                    ]
                  ).find(
                    (key) =>
                      questionTree[
                        question
                      ][key] ===
                      questionTree[
                        question
                      ][
                        answers[
                          question
                        ]
                      ]
                  ) === "Yes"
                    ? "green.100"
                    : "red.500"
                }
                textAlign="center"
                dangerouslySetInnerHTML={{
                  __html:
                    questionTree[
                      question
                    ][
                      answers[question]
                    ],
                }}
              />
            ) : questionTree[question]
                .answers.length ===
              2 ? (
              <Stack
                direction={{
                  base: "column",
                  sm: "row",
                }}
                display="flex"
                justifyContent="center"
                mt="4.25rem"
              >
                {questionTree[
                  question
                ].answers.map(
                  (item) => (
                    <Button
                      w={{
                        base: "full",
                        sm: "173px",
                      }}
                      onClick={() => {
                        setAnswers({
                          ...answers,
                          [question]:
                            item ===
                              "No" ||
                            item ===
                              "Other"
                              ? "No"
                              : "Yes",
                        });
                        question !==
                          4 &&
                          item !==
                            "No" &&
                          setQuestion(
                            question + 1
                          );
                      }}
                      height="100%"
                      minH="52px"
                      whiteSpace="normal"
                      key={item}
                      variant="transparent"
                      _hover={{
                        bg: "green.100",
                        color: "white",
                      }}
                    >
                      <Trans>
                        {item}
                      </Trans>
                    </Button>
                  )
                )}
              </Stack>
            ) : (
              <SimpleGrid
                columns={{
                  base: "1",
                  sm: "3",
                }}
                spacingX="6px"
                spacingY="6px"
                mt="2rem"
              >
                {questionTree[
                  question
                ].answers.map(
                  (item) => (
                    <Button
                      py="10px"
                      fontSize="xs"
                      onClick={() => {
                        setAnswers({
                          ...answers,
                          [question]:
                            item ===
                              "No" ||
                            item ===
                              "Other"
                              ? "No"
                              : "Yes",
                        });
                        item !==
                          "Other" &&
                          setQuestion(
                            question + 1
                          );
                      }}
                      height="100%"
                      minH="52px"
                      whiteSpace="normal"
                      key={item}
                      variant="transparent"
                      _hover={{
                        bg: "green.100",
                        color: "white",
                      }}
                    >
                      <Trans>
                        {item}
                      </Trans>
                    </Button>
                  )
                )}
              </SimpleGrid>
            )}
          </Box>
        </Flex>
        <Flex
          w="full"
          justifyContent="space-around"
        >
          <Text
            color="white"
            fontSize="md"
            textAlign="center"
            mt="5"
          >
            {question + 1} / 4
          </Text>
        </Flex>
      </Box>
    );
  });
export default ExamComplaintsComp;
